import React, { useCallback, useEffect, useState } from "react";
import { InputNumber, Modal, Row, Select, Form, Input } from "antd";

import Services from "../../services";
import Toaster from "../../services/toaster";

import { useUserContext } from "../contexts/usercontext";
import {
  getLimitForMembershipTierAndCategory,
  isUserOfMembershipTiers,
  MembershipTierLimitCategories,
  MembershipTiers,
  showMembershipTierLimitMessage,
} from "../../utils/user";
import { ENTITY_TYPES } from "utils/constants";

const { Option } = Select;

const comparisonOptions = [
  { label: "Above", value: ">" },
  { label: "Below", value: "<" },
];

const AddAlertModal = ({
  alertsFromParentComponent, // E.g. `AlertsDashboard` has alerts already
  visible = false,
  cardId = null,
  sealedWaxId = null,
  onCancel,
  defaultData = {},
  onSubmit = () => {},
}) => {
  const { user } = useUserContext();

  const [name, setName] = useState("");
  const [criteria, setCriteria] = useState("sale");
  const [comparison, setComparison] = useState(undefined);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);
  const [alerts, setAlerts] = useState(Array.isArray(alertsFromParentComponent) ? alertsFromParentComponent : []);

  const fetchAlerts = async () => {
    try {
      const _alerts = await Services.getAlerts(cardId ? ENTITY_TYPES.CARD : ENTITY_TYPES.SEALED_WAX);

      if (!_alerts.error) {
        setAlerts(_alerts);
      }
    } catch (e) {
      Toaster.ErrorNotification("Error fetching alerts", e.message);
    }
  };

  useEffect(() => {
    fetchAlerts();
  }, [cardId, sealedWaxId, loading]);

  useEffect(() => {
    if (defaultData) {
      setName(defaultData.name);
      setCriteria("sale");
      setComparison(defaultData.comparison);
      setValue(defaultData.value);
    }
  }, [defaultData]);

  const handleSubmit = async () => {
    if (isUserOfMembershipTiers(user, [MembershipTiers.STARTER, MembershipTiers.PREMIUM])) {
      const alertsLimit = getLimitForMembershipTierAndCategory(
        user.membership_tier,
        MembershipTierLimitCategories.ALERTS,
      );

      if (alerts.length >= alertsLimit) {
        showMembershipTierLimitMessage(
          `You’ve reached your ${user.membership_tier} plan’s limit for alerts (${alertsLimit}). Remove an alert or upgrade your plan to add more alerts.`,
        );
        return;
      }
    }

    setLoading(true);

    if (name && criteria && comparison && value) {
      const data = {
        name,
        criteria,
        comparison,
        value,
        card_id: cardId,
        sealed_wax_id: sealedWaxId,
      };

      try {
        if (defaultData.id) {
          data.id = defaultData.id;
        }

        // TODO: limit alerts for < unlimited membership tier

        if (defaultData.id && defaultData.triggered_at === null) {
          await Services.activateAlert(defaultData.id);
        }
        const result = defaultData.id ? await Services.updateAlert(data) : await Services.createAlert(data);
        if (!result.error) {
          onSubmit(result);
          handleCancel();
        }
      } catch (e) {
        console.error(e);
      }
    }
    setLoading(false);
  };

  const handleCancel = useCallback(() => {
    setValue(null);
    setComparison(undefined);
    setName("");
    setCriteria(undefined);
    onCancel();
  }, []);

  const okDisabled = !name || !criteria || !comparison || !value;
  return (
    <Modal
      title={`${defaultData.id ? "Update Alert" : "Add an Alert"}`}
      visible={visible}
      onCancel={handleCancel}
      onOk={handleSubmit}
      confirmLoading={loading}
      okButtonProps={{ disabled: okDisabled }}
    >
      <div style={{ margin: "0 14px" }}>
        <Row gutter={24} type="flex" justify="start" style={{ marginBottom: ".5rem", flex: 1 }}>
          <Form labelCol={{ span: 9 }} wrapperCol={{ span: 15 }} style={{ flex: 1 }}>
            <Form.Item label={"Alert Name"} labelAlign={"left"} required>
              <Input
                placeholder={"My alert name ..."}
                onChange={(ev) => setName(ev.target.value)}
                style={{ width: "100%" }}
                value={name}
              />
            </Form.Item>
            <hr />
            <Form.Item label={"Threshold"} labelAlign={"left"} required>
              <Select placeholder={"Above / Below"} onChange={setComparison} value={comparison}>
                {comparisonOptions.map((item) => (
                  <Option key={item.value} value={item.value}>
                    {item.label}
                  </Option>
                ))}
              </Select>
            </Form.Item>
            <Form.Item label={"Price ($)"} labelAlign={"left"} required>
              <InputNumber
                type={"number"}
                onChange={setValue}
                style={{ width: "100%" }}
                placeholder={"$123.45"}
                value={value}
                precision={2}
              />
            </Form.Item>
          </Form>
        </Row>
      </div>
    </Modal>
  );
};

export default AddAlertModal;
