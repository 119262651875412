import React from "react";
import { Button, Tooltip } from "antd";
import { BellOutlined, BellFilled } from "@ant-design/icons";
import { isMobile } from "react-device-detect";

const AlertLink = ({ openAlertModal, cardId, tooltipPlacement = "top", hasAlert = false }) => {
  const clickBtn = (e) => {
    e.stopPropagation();
    openAlertModal(cardId);
  };

  const btn = (
    <Button
      type="link"
      className={"card-action-icon"}
      onClick={(e) => {
        if (!isMobile) {
          clickBtn(e);
        }
      }}
      onTouchEnd={clickBtn}
    >
      {hasAlert ? <BellFilled className="blue" /> : <BellOutlined className="blue" />}
    </Button>
  );

  if (isMobile) {
    return btn;
  }

  return (
    <Tooltip title="Add a Price Alert" placement={tooltipPlacement}>
      {btn}
    </Tooltip>
  );
};

export default AlertLink;
