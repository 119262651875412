import React, { Fragment, useEffect, useState } from "react";
import { Button, Row, Col, DatePicker, Modal, Spin, Typography } from "antd";
import LineChartComponent from "./LineChartComponent";
import Services from "services";
import moment from "moment";
import { CHART_DATE_RANGES, QUICK_DATE_RANGES } from "components/charts/constants";
import { logAndPresentError } from "utils/errors";

const PopCountChartModal = ({ visible, cardId, onCancel }) => {
  const [data, setData] = useState([]);
  const [card, setCard] = useState();
  const [growth, setGrowth] = useState();
  const [growthPercent, setGrowthPercent] = useState();
  const [startDate, setStartDate] = useState(moment().subtract(90, "days"));
  const [endDate, setEndDate] = useState(moment());
  const [dateRangeLabel, setDateRangeLabel] = useState("90");
  const [loading, setLoading] = useState(false);

  const fetchData = async (id, sDate, eDate) => {
    setLoading(true);
    try {
      const results = await Services.getPopCountChartData(id, sDate, eDate);
      setData(results);

      const lastResult = results[results.length - 1],
        firstResult = results[0];

      const growth = lastResult.count - firstResult.count;
      const growthPercent = Number(100 * (lastResult.count / firstResult.count - 1)).toFixed(2);
      setGrowth(growth);
      setGrowthPercent(growthPercent > 10 ? Number(growthPercent).toFixed(0) : growthPercent);
    } catch (error) {
      logAndPresentError(error, {
        messageToUser: "Something went wrong.",
      });
    } finally {
      setLoading(false);
    }
  };

  const fetchCardInfo = async (id) => {
    try {
      const card = await Services.getOneCardMinimal(id);
      setCard(card);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    if (cardId) {
      fetchData(cardId, startDate, endDate);
      fetchCardInfo(cardId);
    }
  }, [cardId, startDate, endDate]);

  return (
    <Modal
      className="pop-count-chart-modal"
      title="Population Growth"
      visible={visible}
      onCancel={onCancel}
      width={720}
      footer={null}
    >
      <div style={{ marginBottom: 8 }}>
        {card ? (
          <Row>
            <Col xs={24} md={15} style={{ paddingRight: 20 }}>
              {loading ? (
                <Spin size="small" />
              ) : (
                <img src={card.image_url ? `${card.image_url}-S` : "/no-image-2.jpg"} />
              )}
              <div className="description-box">
                <Typography.Title>{card.player_name}</Typography.Title>
                <div className="description">
                  {card.set_year} {card.set_name}
                </div>
                <div className="description -mb">
                  {card.variation_name}
                  {card.print_run ? ` ${card.print_run}` : ""}
                  {card.card_number ? ` #${card.card_number}` : ""}
                </div>
                <Typography.Text className="grade">{card.grade_name}</Typography.Text>
                {card.rookie_years?.includes(card.set_year) && <div className="rc-token">RC</div>}
              </div>
            </Col>
            <Col xs={24} md={9} className="stats-box">
              {loading ? (
                <Spin size="small" style={{ paddingTop: 30 }} />
              ) : (
                <div>
                  <label>Population</label>
                  <div>{card.population_count}</div>
                  <label>Growth</label>
                  <div>
                    {growth > 0 && <img src="/arrow-upward.png" style={{ height: 36, verticalAlign: "top" }} />}
                    {growth} ({growthPercent}%)
                  </div>
                </div>
              )}
            </Col>
          </Row>
        ) : (
          <Spin size="small" style={{ marginLeft: 8 }} />
        )}
      </div>

      <div
        className="graph-rangepicker"
        style={{ display: "flex", alignItems: "center", verticalAlign: "center", marginBottom: 16 }}
      >
        <DatePicker.RangePicker
          allowClear={false}
          defaultValue={[startDate, endDate]}
          value={[startDate, endDate]}
          format={"MM/DD/YYYY"}
          onCalendarChange={(change) => {
            setStartDate(change[0]);
            setEndDate(change[1]);
            setDateRangeLabel();
          }}
          ranges={CHART_DATE_RANGES}
          style={{ width: 260, marginRight: 20 }}
        />

        <div className="graph-rangepicker-quick-options">
          <span>Show Last:</span>
          {QUICK_DATE_RANGES.map(({ label, value }, idx) => {
            const selected = dateRangeLabel === label;

            return (
              <Fragment key={label}>
                <Button
                  style={{ padding: "4px 6px" }}
                  type={selected ? "text" : "link"}
                  onClick={() => {
                    setStartDate(value[0]);
                    setEndDate(value[1]);
                    setDateRangeLabel(label);
                  }}
                >
                  {label}
                </Button>
                {idx + 1 !== QUICK_DATE_RANGES.length && <span style={{ margin: "0 6px" }}>|</span>}
              </Fragment>
            );
          })}
        </div>
      </div>

      {loading ? <Spin style={{ width: "100%", height: 300, paddingTop: 130 }} /> : <LineChartComponent data={data} />}

      {card?.serial_number && (
        <div style={{ textAlign: "right", padding: "24px 0 0" }}>
          <a
            href={`https://www.psacard.com/cert/${card?.serial_number}`}
            target="blank"
            style={{ fontSize: ".875rem" }}
          >
            View PSA Site
          </a>
        </div>
      )}
    </Modal>
  );
};

export default PopCountChartModal;
