import React from "react";

export const waxDescription = (item) => item.description;
export const waxTerm = (item) => item.box_type?.name;

export const sportsCardDescription = (item, minimal = true) => {
  const { set_name, card_number, variation_name, player_name, specific_qualifier, set_year, print_run } = item;

  const startText = minimal ? null : `${player_name}`;
  const qualifier = specific_qualifier ? `(${specific_qualifier})` : null;
  const card = card_number ? `#${card_number}` : null;
  const nameYearOrder = minimal ? [set_year, startText] : [startText, set_year];

  const description = [...nameYearOrder, set_name, card, variation_name, print_run, qualifier];

  return description.filter((el) => !!el).join(" ");
};

export const sportsCardTerm = (item) => item.grade_name;

export const WaxItem = ({ item }) => {
  return (
    <div className="search-item">
      <img src={item.image_url || "/no-image.png"} alt={item.description} width="50" height="70" />
      <div className="details">
        <div>
          <span>{waxDescription(item)}</span>
        </div>
        <div>
          <span style={{ fontWeight: 600 }}>{waxTerm(item)}</span>
        </div>
      </div>
    </div>
  );
};

export const SportsCardItem = ({ item }) => {
  return (
    <div className="search-item">
      <img
        src={(item.image_url && `${item.image_url}-S`) || item.admin_image_url || "/no-image.png"}
        alt={item.description}
        width="50"
        height="70"
      />
      <div className="details">
        <div>
          <span>
            {item.player_name}
            {item.rc ? <span className="rookie-card-token">RC</span> : null}
          </span>
        </div>
        <div>
          <span>{sportsCardDescription(item)}</span>
        </div>
        <div>
          <span style={{ fontWeight: 600 }}>{sportsCardTerm(item)}</span>
        </div>
      </div>
    </div>
  );
};
