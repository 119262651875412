import { useEffect, useRef, useState } from "react";

const useTable = ({
  defaultPageSize = 10,
  totalCount = 0,
  initialFilters = {},
  initialSort = {},
  paginationPosition = "bottom",
}) => {
  const isFirstRenderRef = useRef(true);
  const [pageSize, setPageSize] = useState(defaultPageSize);
  const [currentPage, setCurrentPage] = useState(1);
  const [sortBy, setSortBy] = useState(initialSort);
  const [filters, setFilters] = useState(initialFilters);
  const [filtersChanged, setFiltersChanged] = useState(false);

  const handlePageChange = (newPage) => {
    if (currentPage !== newPage) {
      setCurrentPage(newPage);
    }
  };

  const handlePageSizeChange = (_, size) => {
    if (pageSize !== size) {
      setPageSize(size);
      setCurrentPage(1);
    }
  };

  const handleTableChange = (pagination, tableFilters, sorter) => {
    if (Object.keys(tableFilters).length > 0) {
      const currentFilters = Object.entries(tableFilters).reduce((acc, [key, val]) => ({ ...acc, [key]: val }), {});

      if (JSON.stringify(filters) !== JSON.stringify(currentFilters)) {
        setFilters(currentFilters);
      }
    }

    if (sorter.order) {
      setSortBy({
        sortBy: Array.isArray(sorter.field) ? sorter.field.join(".") : sorter.field,
        orderBy: sorter.order.slice(0, -3),
      });
    } else {
      setSortBy(initialSort);
    }
  };

  const resetTable = ({ initialFilters = {}, initialSort = {} } = {}) => {
    setPageSize(defaultPageSize);
    setCurrentPage(1);
    setSortBy(initialSort);
    setFilters(initialFilters);
    setFiltersChanged(false);
  };

  useEffect(() => {
    if (isFirstRenderRef.current) {
      isFirstRenderRef.current = false;
      return;
    }
  }, []);

  return {
    isFirstRender: isFirstRenderRef.current,
    tableProps: {
      pagination: {
        current: currentPage,
        total: totalCount,
        pageSize,
        showTotal: (total, range) => `${range[0]}-${range[1]} of ${total} items`,
        onShowSizeChange: handlePageSizeChange,
        onChange: handlePageChange,
        pageSizeOptions: ["5", "10", "25", "50", "100"],
        defaultPageSize,
        showSizeChanger: true,
        position: paginationPosition,
      },
      onChange: handleTableChange,
    },
    currentPage,
    pageSize,
    filters,
    setFilters,
    filtersChanged,
    setFiltersChanged,
    setCurrentPage,
    sortBy,
    setSortBy,
    goToPage: handlePageChange,
    resetTable,
  };
};

export default useTable;
