import Helpers from "../../services/helpers";
import moment from "moment";

export const ZERO_REFERENCE_LINE = "zeroReference";

export const CHARTS = {
  valuePerDay: "valuePerDay",
  numberOfSales: "numberOfSales",
  totalSales: "totalSales",
  percentageGrowthChart: "percentageGrowthChart",
};

export const CHART_DATE_RANGES = {
  "Last 365 Days": [moment().subtract(1, "year"), moment()],
  "Last 180": [moment().subtract(180, "days"), moment()],
  "Last 90": [moment().subtract(90, "days"), moment()],
  "Last 60": [moment().subtract(60, "days"), moment()],
  "Last 30": [moment().subtract(30, "days"), moment()],
  "Last 14": [moment().subtract(14, "days"), moment()],
  "Last 7": [moment().subtract(7, "days"), moment()],
  "This Year": [moment().startOf("year"), moment()],
  "This Month": [moment().startOf("month"), moment()],
  "All Data": [moment("2000-01-01"), moment()],
};

export const STARTER_WAX_CHART_DATE_RANGES = {
  "Last 30": CHART_DATE_RANGES["Last 30"],
  "Last 14": CHART_DATE_RANGES["Last 14"],
  "Last 7": CHART_DATE_RANGES["Last 7"],
};

export const QUICK_DATE_RANGES = [
  { label: "365", value: [moment().subtract(1, "year"), moment()] },
  { label: "180", value: [moment().subtract(180, "days"), moment()] },
  { label: "90", value: [moment().subtract(90, "days"), moment()] },
  { label: "30", value: [moment().subtract(30, "days"), moment()] },
  { label: "7 Days", value: [moment().subtract(7, "days"), moment()] },
];

export const STARTER_WAX_QUICK_DATE_RANGES = QUICK_DATE_RANGES.slice(3);

export const SHORT_LIST_RANGES = [
  { range: "last30", label: "30", value: [moment().subtract(30, "days"), moment()] },
  { range: "last14", label: "14", value: [moment().subtract(14, "days"), moment()] },
  { range: "last7", label: "7 Days", value: [moment().subtract(7, "days"), moment()] },
];

export const QUERY_RANGE_DATES = {
  last7: { label: "7 Days", value: moment().subtract(7, "days") },
  last14: { label: "14", value: moment().subtract(14, "days") },
  last30: { label: "30", value: moment().subtract(30, "days") },
  last60: { label: "60", value: moment().subtract(60, "days") },
  last90: { label: "90", value: moment().subtract(90, "days") },
  last180: { label: "180", value: moment().subtract(180, "days") },
  last365: { label: "365", value: moment().subtract(365, "days") },
};

export const CHART_LINES = {
  valuePerDay: [
    {
      lineId: "min",
      visible: false,
    },
    {
      lineId: "avg",
      visible: true,
    },
    {
      lineId: "max",
      visible: false,
    },
    {
      lineId: "avg",
      visible: true,
      isTrend: true,
    },
  ],
  numberOfSales: [
    {
      lineId: "count",
      visible: true,
    },
    {
      lineId: "count",
      visible: true,
      isTrend: true,
    },
  ],
  totalSales: [
    {
      lineId: "totalsales",
      visible: true,
    },
    {
      lineId: "totalsales",
      visible: true,
      isTrend: true,
    },
  ],
  percentageGrowthChart: [
    {
      lineId: "percentage",
      visible: true,
    },
    {
      lineId: "percentage",
      visible: false,
      isTrend: true,
    },
  ],
};

const getPercentage = (percentage) => {
  if (!percentage) return "";
  return `(${percentage.toFixed(1)}%)`;
};

export const CHART_OPTIONS = {
  valuePerDay: {
    hasTrendLineControl: true,
    hasYAxisControl: true,
    yTickFormatter: (value) => Helpers.formatMoney(value, 0),
    tooltipEntryValueFormatter: (value, payload, dataKey) => {
      const cardId = +dataKey.split("_")[0];
      return `${Helpers.formatMoney(value)} ${getPercentage(payload[`${cardId}_percentage`])}`;
    },
  },
  numberOfSales: {
    hasTrendLineControl: false,
    hasYAxisControl: false,
    yTickFormatter: (value) => value,
    tooltipEntryValueFormatter: (value) => value,
  },
  totalSales: {
    hasTrendLineControl: false,
    hasYAxisControl: false,
    yTickFormatter: (value) => Helpers.formatMoney(value, 0),
    tooltipEntryValueFormatter: (value) => Helpers.formatMoney(value),
  },
  percentageGrowthChart: {
    hasTrendLineControl: false,
    hasYAxisControl: false,
    yTickFormatter: (value) => `${value}%`,
    tooltipEntryValueFormatter: (value, payload, dataKey) => {
      const cardId = +dataKey.split("_")[0];
      return `${Helpers.formatMoney(payload[`${cardId}_avg`])} (${value.toFixed(1)}%)`;
    },
  },
};
