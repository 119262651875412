import React, { useState, useImperativeHandle, forwardRef, useEffect, useRef } from "react";
import moment from "moment";
import { Form, DatePicker, InputNumber, Modal, Col, Row, Typography, Button } from "antd";
import get from "lodash/get";

import Services from "../../services";
import { sportsCardDescription, waxDescription } from "../modals/AddEntityToCollectionModal/searchItem";
import DynamicCategoryChooser from "./DynamicCategoryChooser";
import { RAW_GRADES } from "../../utils/constants";

const { Text } = Typography;

const CollectionItemModal = (
  { item, saveItem, addItem, onCategoryAdd = () => {}, onlyCards = true, handleEditCategory },
  ref,
) => {
  const [showModal, setShowModal] = useState(false);
  const [quantity, setQuantity] = useState(item.quantity);
  const [datePurchased, setDatePurchased] = useState(item.date_purchased ? moment(item.date_purchased) : null);
  const [purchasePricePerCard, setPurchasePricePerCard] = useState(item.purchase_price_per_card);
  const [categories, setCategories] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState((item.category && item.category.id) || null);
  const [isAddingCategory, setIsAddingCategory] = useState(false);
  const [newCategory, setNewCategory] = useState("");
  const [entityFromPersonalCollection, setEntityFromPersonalCollection] = useState();
  const [isSealedWax, setIsSealedWax] = useState(false);
  const hasAddedCategory = useRef(false);

  useImperativeHandle(ref, () => ({
    toggleModal(val, entity = null, isWax = false) {
      setShowModal(val);
      setEntityFromPersonalCollection(entity);
      setIsSealedWax(isWax);
    },
  }));

  const fetchCategories = async (categoryId = null) => {
    const result = await Services.listCollectionCategories(!isSealedWax);
    if (!result.error) {
      setCategories(result);
      if (result.length === 0) setIsAddingCategory(true);
      const catId = categoryId || get(item, "category.id", null);
      setSelectedCategory(catId && result.find((item) => item.id === catId) ? catId : null);
    }
  };

  useEffect(() => {
    if (showModal) {
      fetchCategories();

      setTimeout(() => {
        const modal = document.querySelector(".ant-modal-wrap");
        if (modal) {
          modal.onmousemove = (e) => e.stopPropagation();
          modal.onpointermove = (e) => e.stopPropagation();
        }
      }, 0);
      // Disable these 2 events since recharts handler interfere with selects
    }
  }, [showModal]);

  useEffect(() => {
    setIsSealedWax(Boolean(!onlyCards || item.sealed_wax_id || entityFromPersonalCollection?.sealed_wax_id));
  }, [item, entityFromPersonalCollection, onlyCards]);

  useEffect(() => {
    const catId = get(item, "category.id", null);
    setSelectedCategory(catId && categories.find((item) => item.id === catId) ? catId : null);
  }, [item]);

  useEffect(() => {
    if (selectedCategory && !categories.find((cat) => cat.id === selectedCategory)) {
      setSelectedCategory(null);
    } else {
      setSelectedCategory(selectedCategory);
    }
  }, [selectedCategory]);

  const reset = (resetItem) => {
    setQuantity(resetItem.quantity);
    setDatePurchased(resetItem.date_purchased ? moment(resetItem.date_purchased) : null);
    setPurchasePricePerCard(resetItem.purchase_price_per_card);
    setSelectedCategory(null);
    setIsAddingCategory(false);
    setNewCategory("");
  };

  const onInputChange = (price) => {
    setPurchasePricePerCard(price);
  };

  const onDateChange = (selectedDate) => {
    setDatePurchased(selectedDate);
  };

  const handleOk = () => {
    if (item.key) {
      const updateItem = {
        id: item.key,
        quantity,
        date_purchased: datePurchased,
        purchase_price_per_card: purchasePricePerCard,
        collection_category_id: selectedCategory ? +selectedCategory : null,
      };

      if (item.isCustomCard) {
        if (item.isSealedWax) {
          updateItem.custom_sealed_wax_id = item.sealed_wax_id;
        } else {
          updateItem.custom_card_id = item.card_id;
        }
      } else {
        if (item.sealed_wax_id) {
          updateItem.sealed_wax_id = item.sealed_wax_id;
        } else {
          updateItem.card_id = item.card_id;
        }
      }

      saveItem(updateItem, item.isCustomCard);
      reset(updateItem);
    } else {
      const newItem = {
        quantity,
        date_purchased: datePurchased,
        purchase_price_per_card: purchasePricePerCard,
        collection_category_id: selectedCategory ? +selectedCategory : null,
      };

      if (item.isCustomCard) {
        if (item.isSealedWax) {
          newItem.custom_sealed_wax_id = item.sealed_wax_id;
        } else {
          newItem.custom_card_id = item.card_id;
        }
      } else if (item.sealed_wax_id) {
        newItem.sealed_wax_id = item.sealed_wax_id;
      } else if (entityFromPersonalCollection) {
        if (entityFromPersonalCollection.sealed_wax_id) {
          newItem.sealed_wax_id = entityFromPersonalCollection.sealed_wax_id;
        } else {
          newItem.card_id = entityFromPersonalCollection.id;
        }
      } else {
        newItem.card_id = item.card_id;
      }
      addItem(newItem, item.isCustomCard);
      reset({ quantity: 1, date_purchased: null, purchase_price_per_card: null });
    }

    if (hasAddedCategory.current) {
      onCategoryAdd();
      hasAddedCategory.current = false;
    }
    setShowModal(false);
  };

  const handleCancel = () => {
    setShowModal(false);
    setIsSealedWax(false);
    if (hasAddedCategory.current) {
      onCategoryAdd();
      hasAddedCategory.current = false;
    }
    reset(item);
  };

  const handleCategoryAdd = async () => {
    // Check to not add a duplicate
    if (!categories.find((cat) => cat.name.toLowerCase() === newCategory.toLowerCase())) {
      const addedCategory = await Services.createCollectionCategory({ name: newCategory, onlyCards: !isSealedWax });
      if (!addedCategory.error) {
        fetchCategories(addedCategory.id);
        setIsAddingCategory(false);
        hasAddedCategory.current = true;
      }
    }
  };

  const handleCategoryBack = () => {
    setIsAddingCategory(false);
    setNewCategory("");
  };

  const handleCategoryConversion = () => {
    setShowModal(false);
    handleEditCategory(item);
  };

  const title = item.key
    ? isSealedWax
      ? "Edit Wax in Collection"
      : "Edit Card in Collection"
    : isSealedWax
    ? "Add Wax to Collection"
    : "Add Card to Collection";

  return (
    <Modal title={title} visible={showModal} onOk={handleOk} onCancel={handleCancel} style={{ zIndex: 1000 }}>
      <Form labelAlign="left" layout="vertical" labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
        {entityFromPersonalCollection && (
          <Form.Item label={`${isSealedWax ? "Wax" : "Card"} Title`} required>
            <Text strong disabled>
              {isSealedWax
                ? waxDescription(entityFromPersonalCollection)
                : sportsCardDescription(entityFromPersonalCollection, false)}
            </Text>
          </Form.Item>
        )}

        <Form.Item label="Quantity" required>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <InputNumber name={"quantity"} value={quantity} onChange={(val) => setQuantity(Math.max(val, 1))} />
            {handleEditCategory && RAW_GRADES.includes(item.grade) && item.card_id ? (
              <Button type="primary" onClick={handleCategoryConversion}>
                Edit Grades
              </Button>
            ) : null}
          </div>
        </Form.Item>

        <Row gutter={24} type="flex" justify="start" style={{ marginBottom: "1.5rem" }}>
          <Col span={24}>
            <hr />
            <Text>Optional Fields:</Text>
          </Col>
        </Row>

        <Form.Item label={`Purchase Price Per ${isSealedWax ? "Wax" : "Card"}`}>
          <InputNumber
            value={purchasePricePerCard}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
            parser={(value) => value.replace(/\$\s?|(,*)/g, "")}
            onChange={onInputChange}
          />
        </Form.Item>

        <Form.Item label="Purchased On">
          <DatePicker name="date_purchased" value={datePurchased} format={"MM/DD/YYYY"} onChange={onDateChange} />
        </Form.Item>

        <Form.Item label="Category">
          <DynamicCategoryChooser
            categories={categories}
            handleCategoryAdd={handleCategoryAdd}
            handleCategoryBack={handleCategoryBack}
            isAddingCategory={isAddingCategory}
            setIsAddingCategory={setIsAddingCategory}
            newCategory={newCategory}
            selectedCategory={selectedCategory}
            setNewCategory={setNewCategory}
            setSelectedCategory={setSelectedCategory}
            width={280}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
};

export default forwardRef(CollectionItemModal);
