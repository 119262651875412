import React from "react";
import { PlusOutlined } from "@ant-design/icons";
import { AutoComplete, Button, Input, Select, Tooltip } from "antd";
import Helpers from "services/helpers";

const DynamicCategoryChooser = ({
  isAddingCategory,
  setIsAddingCategory,
  selectedCategory,
  setSelectedCategory,
  newCategory,
  setNewCategory,
  categories,
  handleCategoryAdd,
  handleCategoryBack,
  width = 200,
}) =>
  isAddingCategory ? (
    <Input.Group compact>
      <AutoComplete
        value={newCategory}
        onChange={setNewCategory}
        placeholder={"e.g. Rising Stars"}
        options={categories.map(({ name }) => ({ label: name, value: name }))}
        filterOption={(inputValue, option) => option.label.toUpperCase().includes(inputValue.toUpperCase())}
        onInputKeyDown={(e) => {
          if (e.key.toLowerCase() === "enter") {
            handleCategoryAdd();
          }
        }}
        style={{ width: width - 95 }}
      />
      <Button.Group>
        <Button type={"primary"} onClick={handleCategoryAdd} disabled={!newCategory}>
          Add
        </Button>
        <Button onClick={handleCategoryBack}>Back</Button>
      </Button.Group>
    </Input.Group>
  ) : (
    <>
      <Select
        style={{ width }}
        value={selectedCategory}
        defaultValue={selectedCategory}
        onChange={setSelectedCategory}
        showSearch
        filterOption={Helpers.filterOption}
      >
        <Select.Option key={"none"} value={null}>
          None
        </Select.Option>
        {categories.map((item) => (
          <Select.Option key={item.id} value={item.id}>
            {item.name}
          </Select.Option>
        ))}
      </Select>
      {handleCategoryAdd ? (
        <Tooltip title={"Add a new category"}>
          <PlusOutlined onClick={() => setIsAddingCategory(true)} className="blue" style={{ marginLeft: 10 }} />
        </Tooltip>
      ) : null}
    </>
  );
export default DynamicCategoryChooser;
