import moment from "moment";
import React from "react";
import { CartesianGrid, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis } from "recharts";

const LineChartComponent = ({ data, stroke = "#004CFE" }) => {
  if (!data) return null;
  data = data.map((item) => ({
    ...item,
    date: moment(item.date).format("MM/DD/YYYY"),
  }));

  return (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart data={data}>
        <Tooltip
          content={(props) => {
            return (
              <div style={{ padding: "10px", borderRadius: 4, border: "1px solid #CCC", background: "#FFF" }}>
                <b>{props.label}</b>: {props.payload?.[0]?.value}
              </div>
            );
          }}
        />
        <XAxis dataKey="date" />
        <YAxis domain={["dataMin", "auto"]} tickFormatter={(item) => item.toFixed(0)} />
        <CartesianGrid stroke="#DDD" strokeDasharray="5 5" />
        <Line dot={false} type="monotone" dataKey="count" stroke={stroke} strokeWidth={2} />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default LineChartComponent;
