import React, { useState } from "react";
import { StarOutlined, StarFilled } from "@ant-design/icons";
import { Button, Tooltip } from "antd";
import { isMobile } from "react-device-detect";

import Helpers from "../../services/helpers";
import Services from "../../services";
import Toaster from "../../services/toaster";
import useGlobal from "../../store";
import { useRouter } from "next/router";
import { COLLECTION_TYPES } from "../../globals";
import { useModalContext } from "../contexts/modalcontext";

const SaveToFavorites = ({ foreignId, type, tooltipPlacement = "top", ...props }) => {
  const [globalState, globalActions] = useGlobal();
  const router = useRouter();
  const favorited = Helpers.isFavorited(foreignId, globalState.shallowFavorites, type);
  const [loading, setLoading] = useState(false);
  const { setSaveToFavoriteData, setIsSaveToFavoriteModalVisible } = useModalContext();

  const saveFavorite = async () => {
    try {
      if (foreignId && !favorited) {
        setIsSaveToFavoriteModalVisible(true);
        setSaveToFavoriteData({ type, foreign_id: foreignId });
      } else {
        setLoading(true);
        const id = Helpers.getShallowFavoriteId(foreignId, globalState.shallowFavorites, type);
        const response = await Services.deleteFavorite([id]);

        if (response && response.error && response.status !== 404) {
          return Toaster.ErrorNotification("Error when deleting favorite.");
        }

        Toaster.SuccessNotification("Favorite removed!");
        globalActions.deleteById({ id, objectType: type, collectionType: COLLECTION_TYPES.FAVORITES });
        const nextShallowFavorites = Array.from(globalState.shallowFavorites).filter(
          (favorite) => !(favorite.id === id && favorite.type === type),
        );
        globalActions.setShallowFavorites(nextShallowFavorites);
      }
    } catch (e) {
      Helpers.handleApiError(e, router);
    } finally {
      setLoading(false);
    }
  };

  const btn = (
    <Button
      {...props}
      type="link"
      disabled={loading}
      icon={favorited ? <StarFilled className="blue" /> : <StarOutlined className="blue" />}
      onClick={() => {
        if (!isMobile) {
          saveFavorite();
        }
      }}
      onTouchEnd={saveFavorite}
    />
  );

  if (isMobile) {
    return btn;
  }

  return (
    <Tooltip title={`${favorited ? "Remove from" : "Add to"} Favorites`} placement={tooltipPlacement}>
      {btn}
    </Tooltip>
  );
};

export { SaveToFavorites };
