import React, { useRef } from "react";
import { isMobile } from "react-device-detect";
import { CheckSquareFilled, PlusSquareOutlined } from "@ant-design/icons";
import { Button, Tooltip } from "antd";

import Services from "../../services";
import { useUserContext } from "../contexts/usercontext";
import {
  isUserOfMembershipTier,
  MembershipTiers,
  getLimitForMembershipTierAndCategory,
  MembershipTierLimitCategories,
  showMembershipTierLimitMessage,
} from "../../utils/user";
import CollectionItemModal from "./CollectionItemModal";
import Toaster from "../../services/toaster";
import useGlobal from "../../store";
import { ENTITY_TYPES } from "utils/constants";

const AddToCollectionButton = ({ cardId, entityType = ENTITY_TYPES.CARD, tooltipPlacement = "top", ...props }) => {
  const [globalState, globalActions] = useGlobal();
  const { user } = useUserContext();

  const [id, label, href, identifier, collectionIds, setCollectionIds] = {
    [ENTITY_TYPES.CARD]: [
      cardId,
      "Card",
      "/mycollection",
      "card_id",
      globalState.collectionCardIds,
      globalActions.setCollectionCardIds,
    ],
    [ENTITY_TYPES.SEALED_WAX]: [
      cardId,
      "Wax",
      "/mycollection/sealed-wax",
      "sealed_wax_id",
      globalState.collectionSealedWaxIds,
      globalActions.setCollectionSealedWaxIds,
    ],
  }[entityType];

  const inCollection = collectionIds.includes(Number(id));

  let shouldShowLimitMessage = false;
  const collectionLimit = getLimitForMembershipTierAndCategory(
    user.membership_tier,
    MembershipTierLimitCategories.COLLECTION,
  );
  // TODO: account for differences in wax and cards (entity types) for these limits
  if (!isUserOfMembershipTier(user, MembershipTiers.ULTIMATE) && collectionIds.length >= collectionLimit)
    shouldShowLimitMessage = true;
  if (isUserOfMembershipTier(user, MembershipTiers.STARTER) && entityType === ENTITY_TYPES.SEALED_WAX)
    shouldShowLimitMessage = true;

  const addItem = async (data) => {
    const { collection_category_id, ...item } = data;

    if (entityType === ENTITY_TYPES.SEALED_WAX && user.membership_tier === MembershipTiers.STARTER)
      return Toaster.ErrorNotification("Starter members cannot add sealed wax");

    // TODO: integrate w/ `MEMBERSHIP_TIER_LIMIT_CATEGORY_COLLECTION`
    if (
      entityType === ENTITY_TYPES.CARD &&
      user.membership_tier.toUpperCase() === MembershipTiers.STARTER &&
      collectionIds.length >= 10
    )
      return Toaster.ErrorNotification("Starter plan members are limited to 10 collection items");
    if (user.membership_tier.toUpperCase() === MembershipTiers.PREMIUM && collectionIds.length >= 50)
      return Toaster.ErrorNotification("Premium plan members are limited to 50 collection items");

    if (entityType === ENTITY_TYPES.SEALED_WAX) {
      item.onlyCards = false;
    }
    const newItem = await Services.createPersonalCollectionItem(item);
    if (collection_category_id) {
      await Services.associateCollectionCategory(newItem.id, collection_category_id);
    }
    Toaster.SuccessNotification("Collection item added.");
    let nextCollectionIds = Array.from(collectionIds);
    nextCollectionIds = nextCollectionIds.concat(newItem[identifier]);
    setCollectionIds(nextCollectionIds);
  };

  const clickBtn = (e) => {
    e.stopPropagation();

    if (inCollection) {
      window.open(href);
    } else if (shouldShowLimitMessage) {
      showMembershipTierLimitMessage(
        entityType === ENTITY_TYPES.PLAYER
          ? "Your plan doesn’t include a wax collection. Upgrade your plan to track a wax collection."
          : "You’ve reached your plan’s limit for items in your collection. Remove an item or upgrade your plan to add more items.",
      );
    } else {
      modalRef.current.toggleModal(true, null, entityType === ENTITY_TYPES.SEALED_WAX);
    }
  };

  const modalRef = useRef();

  let icon;

  if (inCollection) {
    if (isMobile) {
      icon = <CheckSquareFilled twoToneColor="#1156f7" />;
    } else {
      icon = (
        <Tooltip title={`This ${label} is already in your collection.`} placement={tooltipPlacement}>
          <CheckSquareFilled twoToneColor="#1156f7" />
        </Tooltip>
      );
    }
  } else {
    if (isMobile) {
      icon = <PlusSquareOutlined className="blue" />;
    } else {
      icon = (
        <Tooltip title="Add to Collection" placement={tooltipPlacement}>
          <PlusSquareOutlined className="blue" />
        </Tooltip>
      );
    }
  }

  return (
    <>
      <Button
        {...props}
        type="link"
        onClick={(e) => {
          if (!isMobile) {
            clickBtn(e);
          }
        }}
        onTouchEnd={clickBtn}
      >
        {icon}
      </Button>
      <CollectionItemModal
        item={{
          quantity: 1,
          [identifier]: id,
        }}
        addItem={addItem}
        ref={modalRef}
        entityType={entityType}
      />
    </>
  );
};

export default AddToCollectionButton;
